import React, { useCallback, useEffect, useMemo, useState, useContext, useRef } from 'react';
import { BsChevronRight } from 'react-icons/bs';
import { AiOutlineFile } from 'react-icons/ai';
import { useNavigate, useParams, useRoutes } from 'react-router-dom';
import {
  orderBudgets,
  orderValuations,
  paymentSchedules,
  summaryCards,
  transactionHistory
} from './constants';
import GroupCard from './GroupCard';
import ScheduleItem from './ScheduleItem';
import Transaction from './Transaction';
import { VscChecklist } from 'react-icons/vsc';
import RecentPaymentsModal from './RecentPaymentsModal';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import UpcomingPayments from './UpcomingPayments';
import { updateField, openModal, closeModal, PaymentType } from 'store/slices/financeSlice';
import BudgetModal from './BudgetModal';
import { StoreContext } from 'context';
import useRole from 'Hooks/useRole';
import { TbBook, TbChecklist, TbPlus, TbReceipt2 } from 'react-icons/tb';
import nocontent from 'assets/nocontent.svg';
import ExpenditureModal from './ExpenditureModal';
import Button from 'components/shared/Button';
import { HiChevronUpDown } from 'react-icons/hi2';
import { TheOption } from 'pages/projects/Home/Components/ProjectCard';
import { PiClockCounterClockwise } from 'react-icons/pi';
import ValuationModal from './ValuationModal';
import { convertToProper } from 'components/shared/utils';
import { useClickOutSideComponent } from '../Team/Views/Components/OnScreen';
import { setModal } from 'store/slices/contractorProfileSlice';

const FinancialInfoPage = () => {
  const { projectId } = useParams();
  let dispatch = useAppDispatch();
  const [recentPayments, setRecentPayments] = useState(false);
  const [budgetModal, setBudgetModal] = useState(false);
  const [expenditureModal, setExpenditureModal] = useState(false);
  let finance = useAppSelector((m) => m.finance);
  let navigateTo = useNavigate();
  let user = useAppSelector((m) => m.user);
  let { canUseBookKeeping, isProfessional } = useRole();
  const [recordValuationModal, setRecordValuationModal] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  let optionsRef = useRef(null);

  useClickOutSideComponent(optionsRef, () => {
    setShowOptions(false);
  });

  const tally = useMemo(() => {
    const mtally = [finance.data.estimatedBudget, 0, 0, 0];

    if (finance.data.projectBudget) {
      let { projectBudget } = finance.data;
      let len = projectBudget.length;
      if (len > 0) {
        let recentBudget = orderBudgets(projectBudget || [])[0];
        if (recentBudget) {
          mtally[0] = isProfessional
            ? recentBudget.contractorBudget
            : recentBudget.adminFees +
              recentBudget.contractorBudget +
              recentBudget.materialBudget +
              (recentBudget.otherFees || 0);
        }
      }
    }

    if (finance.data.payments) {
      let total = 0;
      let payments = finance.data.payments;
      for (let i = 0; i < payments.length; i++) {
        if (payments[i].isConfirmed === true) {
          total += isProfessional && payments[i]?.type !== 'contractor' ? 0 : payments[i].amount;
        }
      }
      mtally[2] = total;
    }

    if (finance.data.expenditure) {
      let total = 0;
      for (const exp of finance.data.expenditure) {
        total += exp.amount;
      }
      mtally[1] = total;
    }

    if (finance.data?.valuation) {
      let { valuation } = finance.data;
      let len = valuation.length;
      if (len > 0) {
        mtally[3] = orderValuations(valuation)[0].amount;
      }
    }

    return mtally;
  }, [finance]);

  const findUpcoming = useCallback(() => {
    let { disbursements } = finance.data;
    let upcoming: any[] | undefined = [
      ...(disbursements?.filter((m) => {
        let today = Math.floor(new Date().getTime() / (1000 * 60 * 60 * 24));
        let nextDate = Math.floor(new Date(m.dueDate).getTime() / (1000 * 60 * 60 * 24));
        return today <= nextDate;
      }) as [])
    ].sort((a: any, b: any) => (a.dueDate > b.dueDate ? 1 : b.dueDate > a.dueDate ? -1 : 0));
    if (upcoming && upcoming.length > 0) {
      return upcoming[0];
    } else if (disbursements) {
      return disbursements[disbursements.length - 1];
    } else {
      return undefined;
    }
  }, [finance.data]);

  const [currentSelection, setCurrentSelection] = useState<PaymentType | 'all'>(
    isProfessional ? 'contractor' : 'all'
  );

  const paymentsList = useMemo(() => {
    return (
      finance.data.payments?.filter((m) => {
        if (currentSelection === 'all') return true;
        if (m.type === currentSelection) return true;
        if (!m?.type && currentSelection === 'contractor') return true;
        return false;
      }) || []
    );
  }, [currentSelection, finance.data]);

  useEffect(() => {
    if (!finance.data.bidId) {
      navigateTo(`/projects/${projectId}/financials`);
    }
  }, []);

  const sortedExpenditures = useMemo(() => {
    if (!finance.data.expenditure) return [];
    else return [...finance.data.expenditure].reverse();
  }, [finance.data.expenditure]);

  const Upcoming = useCallback(
    ({ className }: { className?: string }) => {
      return (
        <div className={`bg-white pb-3 pt-2 px-4 rounded-md ${className || ''}`}>
          {finance.data.disbursements && finance.data.disbursements.length > 0 ? (
            <>
              <div className="flex my-4 items-center justify-between text-sm">
                <span className="font-semibold">Upcoming Payment</span>
              </div>
              {findUpcoming() ? (
                <>
                  <UpcomingPayments
                    {...findUpcoming()}
                    contractor={finance.data.contractor || ''}
                  />
                  <span
                    onClick={() => navigateTo('disbursement-plan')}
                    className="text-bblue text-sm  w-full flex items-center justify-center mt-2 hover:underline cursor-pointer">
                    <span className="font-semibold ">View disbursement plan</span>
                  </span>
                </>
              ) : (
                <p className="text-center mt-4 py-2 bg-ashShade-0 rounded-md">
                  No Disbursement Plan yet
                </p>
              )}
            </>
          ) : (
            <div className="flex flex-col justify-center items-center">
              <div className=" bg-ashShade-0 mt-6 my-4 rounded-lg w-full flex flex-col items-center py-5">
                <VscChecklist size={32} color="#77828D" />
                <span className=" mt-3 text-bash">No Payments recorded yet</span>
              </div>
              <span
                onClick={() => navigateTo('disbursement-plan')}
                className="text-bblue font-Medium cursor-pointer hover:underline">
                {'+  Create disbursement plan'}
              </span>
            </div>
          )}
        </div>
      );
    },
    [finance.data]
  );

  return (
    <div className="w-full flex-1 h-full  flex-col pb-10 ">
      {finance.modal.open && finance.modal.name === 'payment' && (
        <RecentPaymentsModal setter={setRecentPayments} />
      )}
      {budgetModal && <BudgetModal setter={setBudgetModal} />}
      {recordValuationModal && <ValuationModal closer={() => setRecordValuationModal(false)} />}
      {expenditureModal && <ExpenditureModal closer={() => setExpenditureModal(false)} />}
      <div className="flex sticky bg-projectBg mt-6 top-0 z-20 items-center pb-2  text-sm cursor-pointer">
        <span onClick={() => navigateTo(-1)} className="text-borange mr-2 flex  items-center">
          Financials <BsChevronRight className="text-borange ml-2" />{' '}
        </span>
        <span className="text-bash ">{`${finance.data.bidName} Finance`}</span>
      </div>
      <div
        className="mt-6 md:mt-0 flex items-center justify-between
      ">
        <span className="text-black font-semibold  text-2xl font-Medium">
          {finance.data.bidName} Finance
        </span>
        {canUseBookKeeping && (
          <Button
            onClick={() => {
              navigateTo(`/projects/${projectId}/financials/book-keeping`);
            }}
            type="plain"
            className=" !text-bblue hover:underline !text-sm md:text-base !border-0 md:!border !px-2 md:!px-4  items-center my-4 !border-bblue "
            text="Book Keeping"
            LeftIcon={<TbReceipt2 className=" mr-1" />}
          />
        )}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-5 mt-5">
        <GroupCard
          removeOptions={isProfessional}
          {...summaryCards[0]}
          amount={tally[0]}
          options={
            <div className=" shadow-bnkle bg-white rounded-md ">
              <TheOption
                icon={TbPlus}
                className=" text-sm whitespace-nowrap  "
                text="Update Project Estimate"
                onClick={() => {
                  setBudgetModal(true);
                }}
              />
              <TheOption
                icon={PiClockCounterClockwise}
                className=" text-sm whitespace-nowrap "
                text="View Estimate history"
                onClick={() => {
                  navigateTo('./budget-history');
                }}
              />
            </div>
          }
        />
        <GroupCard {...summaryCards[2]} amount={tally[2]} removeOptions />
        <GroupCard
          removeOptions={isProfessional}
          {...summaryCards[1]}
          amount={tally[1]}
          options={
            <div className=" shadow-bnkle bg-white rounded-md ">
              <TheOption
                className=" text-sm whitespace-nowrap  "
                text="Record Expenditure"
                onClick={() => {
                  // setRecordValuationModal(true);
                  setExpenditureModal(true);
                }}
              />
            </div>
          }
        />

        <GroupCard
          {...summaryCards[3]}
          removeOptions={isProfessional}
          amount={tally[3]}
          options={
            <div className=" shadow-bnkle bg-white rounded-md ">
              <TheOption
                className=" text-sm whitespace-nowrap  "
                icon={TbPlus}
                text="Record Valuation"
                onClick={() => {
                  setRecordValuationModal(true);
                }}
              />
              <TheOption
                className=" text-sm whitespace-nowrap "
                icon={PiClockCounterClockwise}
                text="View Valuation history"
                onClick={() => {
                  navigateTo('./valuation');
                }}
              />
            </div>
          }
        />
      </div>
      <Upcoming className=" xl:hidden mt-6" />
      <div className="grid grid-cols-1  xl:grid-cols-3   gap-5 mt-5 pb-10">
        <div className=" bg-white xl:col-span-2 py-2 px-2  md:py-8 md:px-6 rounded-md">
          <div className="flex justify-between items-center">
            <span className="font-semibold relative text-xs flex items-center">
              {convertToProper(currentSelection === 'others' ? 'other' : currentSelection)} Payment
              History
              {!isProfessional && (
                <span
                  onClick={() => setShowOptions(!showOptions)}
                  className="p-1 ml-1 rounded-full hover:bg-ashShade-0">
                  <HiChevronUpDown />
                </span>
              )}
              {showOptions && (
                <span
                  ref={optionsRef}
                  className=" absolute right-0 z-10 bg-white rounded-md shadow-bnkle top-4">
                  {['all', 'contractor', 'admin', 'others'].map((m) => (
                    <TheOption
                      onClick={() => {
                        setCurrentSelection(m as PaymentType);
                        setShowOptions(false);
                      }}
                      text={convertToProper(m)}
                    />
                  ))}
                </span>
              )}
            </span>
            {finance.data.payments && finance.data?.payments?.length > 0 ? (
              !isProfessional ? (
                <span
                  onClick={() => {
                    dispatch(openModal({ name: 'payment', isEditing: false }));
                  }}
                  className=" hover:underline text-xs whitespace-nowrap  cursor-pointer text-bblue md:text-sm font-semibold border-bblue  ">
                  + Record New Payment
                </span>
              ) : null
            ) : null}
          </div>

          <div className="w-full h-full pt-4 max-h-[700px] overflow-y-auto  ">
            {paymentsList.map((m, i: number) => (
              <ScheduleItem
                {...m}
                _id={m._id}
                isfirst={i === 0}
                key={m._id}
                id={i}
                islast={finance.data.payments && finance.data.payments.length - 1 === i}
              />
            ))}
            {paymentsList.length > 0 ? null : (
              <div className="w-full  flex flex-col items-center mt-20 justify-center ">
                <img src={nocontent} alt="" className="margin-auto w-2/5 " />
                <span className=" mt-3 text-bash">No Payments recorded yet</span>
                {isProfessional ? null : (
                  <span
                    onClick={() => {
                      dispatch(openModal({ isEditing: false, name: 'payment' }));
                    }}
                    className=" border cursor-pointer text-bblue font-semibold border-bblue px-8 py-2 rounded-md mt-8">
                    Record New Payment
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
        <div className=" xl:col-span-1 h-full ">
          <Upcoming className=" hidden xl:block" />
          {/* <div className="mt-5 rounded-md bg-white px-4 py-6  relative overflow-y-auto">
            <p className="font-Medium mt-3  ">Transaction History</p>
            {sortedExpenditures && sortedExpenditures.length > 0 ? (
              sortedExpenditures.map((m) => <Transaction key={m.title} {...m} />)
            ) : (
              <div className="  mt-6 my-10 rounded-lg w-full flex flex-col items-center py-5">
                <img src={nocontent} alt="" className="margin-auto w-3/5 " />
                <span className=" text-bblack-0 font-semibold text-sm">
                  No transactions done yet
                </span>
              </div>
            )}
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default FinancialInfoPage;
