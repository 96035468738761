import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';

interface DashboardSummaryType {
  loading: boolean;
  data?: SummaryType;
}

let initialState: DashboardSummaryType = {
  loading: true
};
export const summarySlice = createSlice({
  name: 'summary',
  initialState,
  reducers: {
    setDashboardSummary: (state, action) => {
      let newState = state;
      for (let field in action.payload) {
        newState[field as keyof DashboardSummaryType] = action.payload[field];
      }

      return newState;
    }
  }
});

export const { setDashboardSummary } = summarySlice.actions;

export const selectSummary = (state: RootState) => state.summary.data;
export default summarySlice.reducer;

export interface SummaryType {
  upcomingPayment: UpcomingPayment;
  projectCostEstimate: ProjectCostEstimate;
  valuation: Valuation;
  totalDisbursements: TotalDisbursements;
  presentationMedia: PresentationMedum[];
  photosAndVideos: PhotosAndVideo[];
  futureMeetings: any[];
  tasks: Task[];
  subtasksBehindSchedule: SubtasksBehindSchedule[];
}

export interface UpcomingPayment {
  amount: number;
  dueDate: string;
  contractorName?: string;
}

export interface ProjectCostEstimate {
  contractorBudget: number;
  materialBudget: number;
  adminFees: number;
  otherFees: number;
  totalAmount: number;
}

export interface Valuation {
  amount: number;
  dateOfValuation?: string;
}

export interface TotalDisbursements {
  contractorPayment: number;
  adminPayment: number;
  otherPayment: number;
  materials: number;
  totalAmount: number;
}

export interface PresentationMedum {
  S3Key: string;
  Bucket: string;
  ContentType: string;
  alias: string;
  size: number;
  folder: string;
}

export interface PhotosAndVideo {
  S3Key: string;
  Bucket: string;
  ContentType: string;
  alias: string;
  size: number;
  folder: string;
}

export interface Task {
  taskId: string;
  name: string;
  startDate: string;
  endDate: string;
  numberOfSubtasks: number;
  subtasks: Subtask[];
  status: string;
}

export interface Subtask {
  name: string;
  budget: Budget;
  startDate: StartDate;
  endDate: string;
  plannedStartDate: string;
  plannedEndDate: string;
  duration: number;
  status: string;
  dependencies: string[];
}

export interface Budget {
  value: number;
  state: string;
  timestamp: string;
}

export interface SubtasksBehindSchedule {
  subtaskId: string;
  name: string;
  plannedStartDate: string;
  plannedEndDate: string;
  duration: number;
  startDate: StartDate;
  endDate: string;
  daysBehindSchedule: number;
  status: string;
}

export interface StartDate {
  value: string | Date | any;
  state: string;
  timestamp: string;
}
