import { postForm } from 'apis/postForm';
import { StoreContext } from 'context';
import React, { useContext, useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import { setDashboardSummary } from 'store/slices/summarySlice';

const useProjectSummary = (wait = false) => {
  let { activeProject } = useContext(StoreContext);
  let dispatch = useAppDispatch();
  const fetchDashboard = async () => {
    dispatch(setDashboardSummary({ loading: true, data: undefined }));
    let { e, response } = await postForm(
      'get',
      `projects/dashboard/?projectId=${activeProject?._id}`
    );
    if (response) {
      dispatch(setDashboardSummary({ data: response.data.data, loading: false }));
    }
  };
  useEffect(() => {
    if (!wait) {
      fetchDashboard();
    }
  }, [activeProject]);

  return { fetchDashboard };
};

export default useProjectSummary;
