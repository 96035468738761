import useBookKeeping from 'Hooks/useBookKeeping';
import useProjectFinancials from 'Hooks/useProjectFinancials';
import useRole from 'Hooks/useRole';
import { formatWithComma, toMonthDayCommaYear } from 'Utils';
import { useClickOutSideComponent } from 'components/projects/Team/Views/Components/OnScreen';
import { GhostCard } from 'components/projects/bids/projectowner/bid/GhostBids';
import {
  orderBudgets,
  orderValuations,
  summaryCards
} from 'components/projects/financials/constants';
import { GroupCardProps } from 'components/projects/financials/types';
import { convertToProper } from 'components/shared/utils';
import { StoreContext } from 'context';
import { orderFields, removeemptyFields, removeFields } from 'pages/projectform/utils';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { HiOutlineCash } from 'react-icons/hi';
import { TbDiamond, TbReceipt, TbWallet } from 'react-icons/tb';
import { useAppSelector } from 'store/hooks';
import { SummaryType } from 'store/slices/summarySlice';

interface SummaryGroupCardProps extends GroupCardProps {
  subscripts?: React.ReactElement;
  loading?: boolean;
}

let statClassNames = ['bg-bgreen-4', ' bg-bred', ' bg-borange', ' bg-pbg ', ' bg-bblue '];

const paymentMap = {
  others: 'Other',
  admin: 'Admin'
};

const handleDetails = (str: string) => {
  let options = ['contractor', 'admin', 'material', 'other'];

  for (let i = 0; i < options.length; i++) {
    if (str.startsWith(options[i])) return convertToProper(options[i]);
  }
  return str;
};

const Budget = () => {
  const { data, loading } = useAppSelector((m) => m.summary);

  let { isProfessional } = useRole();

  let { selectedProject } = useContext(StoreContext);

  const [showEstimateStats, setShowEstimateStats] = useState(false);
  const estimateStatsRef = useRef<HTMLDivElement>(null);

  useClickOutSideComponent(estimateStatsRef, () => {
    setShowEstimateStats(false);
  });

  const [showDisbursementStats, setShowDisbursementStats] = useState(false);
  const disbursementStatsRef = useRef<HTMLDivElement>(null);

  useClickOutSideComponent(disbursementStatsRef, () => {
    setShowDisbursementStats(false);
  });

  return (
    <div className="grid grid-cols-1 sm:grid-cols-3  gap-4">
      <BudgetCard
        loading={loading}
        {...projectSummaryCards[0]}
        amount={data?.projectCostEstimate.totalAmount || 0}
        subscripts={
          <div
            ref={estimateStatsRef}
            className={`text-xs mt-3 cursor-pointer ${isProfessional ? ' hidden ' : ''}`}>
            <span
              className="p-2"
              onClick={() => {
                setShowEstimateStats(!showEstimateStats);
              }}>
              {`View ${showEstimateStats ? 'Less' : 'More'}`}
            </span>
            {showEstimateStats && (
              <div className="absolute rounded-b-md shadow-bnkle flex flex-col gap-y-3 p-4 top-[calc(100%-10px)] left-0 z-10 w-full bg-white">
                {Object.entries(
                  orderFields(
                    removeFields(data?.projectCostEstimate || {}, [
                      'totalAmount'
                    ]) as SummaryType['projectCostEstimate'],
                    ['contractorBudget', 'materialBudget', 'adminFees', 'otherFees']
                  )
                ).map((val: [string, number], i: number) => (
                  <div className=" flex items-center  justify-between ">
                    <div className=" flex items-center">
                      <span className={`${statClassNames[i]} w-3 h-3 rounded-sm `}></span>
                      <span className=" ml-2 text-bash">{handleDetails(val[0])}</span>
                    </div>
                    <span>{` ${selectedProject.currency?.code} ${formatWithComma(val[1])}`}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        }
      />
      <BudgetCard
        loading={loading}
        {...projectSummaryCards[1]}
        amount={data?.valuation.amount || 0}
        subscripts={
          <div className="text-xs mt-3">
            Last Updated:{' '}
            <span className=" font-semibold">
              {toMonthDayCommaYear(data?.valuation?.dateOfValuation)}
            </span>
          </div>
        }
      />
      <BudgetCard
        loading={loading}
        {...projectSummaryCards[2]}
        amount={data?.totalDisbursements?.totalAmount || 0}
        subscripts={
          <div
            ref={disbursementStatsRef}
            className={`text-xs mt-3 cursor-pointer ${isProfessional ? ' hidden ' : ''}`}>
            <span
              className="p-2"
              onClick={() => {
                setShowDisbursementStats(!showDisbursementStats);
              }}>
              {`View ${showDisbursementStats ? 'Less' : 'More'}`}
            </span>
            {showDisbursementStats && (
              <div className="absolute rounded-b-md shadow-bnkle flex flex-col gap-y-3 p-4 top-[calc(100%-10px)] left-0 z-10 w-full bg-white">
                {Object.entries(
                  orderFields(
                    removeFields(data?.totalDisbursements || {}, [
                      'totalAmount'
                    ]) as SummaryType['totalDisbursements'],
                    ['contractorPayment', 'materials', 'adminPayment', 'otherPayment']
                  )
                ).map((val: [string, number], i: number) => (
                  <div className=" flex items-center  justify-between ">
                    <div className=" flex items-center">
                      <span className={`${statClassNames[i]} w-3 h-3 rounded-sm `}></span>
                      <span className=" ml-2 text-bash">{handleDetails(val[0])}</span>
                    </div>
                    <span>{` ${selectedProject.currency?.code} ${formatWithComma(val[1])}`}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        }
      />
    </div>
  );
};

const BudgetCard = ({ amount, title, icon, subscripts, loading }: SummaryGroupCardProps) => {
  const { selectedProject } = useContext(StoreContext);
  return (
    <div className="p-4 flex-1 bg-white rounded-md relative">
      <div className="w-full h-full ">
        <div className=" flex items-center">
          <div className="p-1 rounded-full bg-blueShades-1 flex justify-center  items-center">
            {React.createElement(icon, {
              size: 16,
              color: '#437ADB'
            })}
          </div>
          <p className="text-bash text-sm ml-3">{title}</p>
        </div>
        {loading ? (
          <GhostDetails />
        ) : (
          <>
            <div className=" flex flex-col mt-3">
              <p className="text-bblack-1 text-sm lg:text-xl mt-1 font-semibold">
                {selectedProject.currency?.code} {formatter.format(amount as number)}
              </p>
            </div>
            {subscripts}
          </>
        )}
      </div>
    </div>
  );
};

interface GhostDetailsProps {
  className?: string;
  element?: React.ReactElement;
}

export const GhostDetails = ({ className, element }: GhostDetailsProps) => {
  return (
    <div className={`mt-3  py-2 animate-pulse_fast w-full ${className} `}>
      {element ? (
        <>{element}</>
      ) : (
        <>
          <div className=" bg-gray-100 w-full rounded-md h-8"></div>
          <div className="w-1/3 bg-gray-100 mt-2 h-3 rounded-md"></div>
        </>
      )}
    </div>
  );
};

const formatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

let projectSummaryCards: GroupCardProps[] = [
  {
    icon: TbWallet,
    title: 'Project Cost Estimate',
    // amount: "300,000,000",
    btn: true,
    btntitle: 'Update budget',
    bgColor: '#F6E8ED',
    iconColor: '#A7194B'
  },

  {
    icon: TbDiamond,
    title: 'Consultant Valuation',
    // amount: "0.00",
    bgColor: '#E9F6F2',
    iconColor: '#26A87D'
  },
  {
    icon: HiOutlineCash,
    title: 'Total Amount Disbursed',
    // amount: "0.00",
    bgColor: '#ECF2FB',
    iconColor: '#437ADB'
  }
];

export { formatter, projectSummaryCards };
export default Budget;
